<template>
  <div class="w-full h-full">
    <Redeployment mode="admin" />
  </div>
</template>

<script>
import Redeployment from "@/components/redeployment";

export default {
  name: 'RedeploymentView',
  components: {
    Redeployment
  }
}
</script>